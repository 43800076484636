import * as React from "react"
import { Link } from "gatsby"


// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  // Divider,
  // Dropdown,
  // Grid,
  Header,
  Image,
  List,
  // Menu,
  // Segment,
  // Checkbox,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'
import Tabs from '../components/Tabs.js'
import ContactInfo from "../components/ContactInfo.js"

// styles



// data


// markup
const AboutPage = () => {
  return (
  <div>
  <header role="banner">
      <HeaderBar />
    </header>
    <main role="main">

    <Container text style={{ marginTop: '7em' }}>
      <Header as='h1'>About Me</Header>
      <table border="0" style={{borderSpacing: '12px'}}><tr>
        <td width="200" style={{textAlign: 'center'}}>
          <Image src='/images/chrishall/Christopher-C-Hall.jpg'  rounded />
          <br/>
          <b>Dr. Christopher C. Hall, PhD</b><br/>
          <i>Digital Plant Biologist</i><br/>
        </td><td>
          <p>Hello! My name is Christopher Hall, and I am a digital plant biologist.</p>
          <p>What is a digital plant biologist? I'm a bit like Matt Damon's character in the movie <Link to="https://www.imdb.com/title/tt3659388/"><i>The Martian</i></Link>: a tech-savvy biologist, programmer, and engineer. I invent new tools and technologies and use them in both industry and science.</p> 
          <p>For example, I was the lead designer of the <Link to="https://youtu.be/5qimOVqRRjA">Industry-4.0 Algae Lab</Link> at the University of 
            Technology Sydney. This facility combines an Internet of Things (IoT) sensor network and 
            machine learning AI with living microalgae and biochemistry to create a first-of-its-kind 
            self-learning photosynthetic biomanufacturing facility.</p> 
          <p><i>That</i> is digital plant biology.</p>
      </td></tr></table>

      <Container text style={{ marginTop: '2em' }}>
        <Header as='h1'><Image src='/images/DrPlantabyte-logo-400px.png' size='mini' circular style={{verticalAlign: 'baseline'}} />Skills of a Digital Plant Biologist</Header>
        <Tabs>
        <div label="Biology">
            <List bulleted>
<List.Item><strong>Phenomics:</strong> high-throughput phenotyping, mutant screening, recombinant inbred lines, QTL mapping</List.Item>
<List.Item><strong>Bioinformatics:</strong> genomics, BLAST, TAIR</List.Item>
<List.Item><strong>Immunoassays:</strong> ELISA, western-blot</List.Item>
<List.Item><strong>Microbiology:</strong> cell culture, asceptic techniques, bright & dark field microscopy</List.Item>
<List.Item><strong>Plant & Algae Biology:</strong> identification, cultivation, molecular breeding</List.Item>
<List.Item><strong>Spectrophotometry:</strong> absorption, fluorescence, deconvolution</List.Item>
            </List>
          </div>
          <div label="Software Engineering">
            <List bulleted>
<List.Item><strong>Programming Languages:</strong> Python, Java, Rust, JavaScript, C, C++, SQL, Go, R, Visual Basic, Verilog</List.Item>
<List.Item><strong>Machine Vision:</strong> high-speed cameras, hyperspectral imaging, image analysis</List.Item>
<List.Item><strong>Web Development:</strong> Node, Docker, React, Gatsby, Flask, MySQL, Redis, GlassFish</List.Item>
<List.Item><strong>Linux System Administration</strong></List.Item>
<List.Item><strong>Cloud Services:</strong> AWS</List.Item>
            </List>
          </div>
          <div label="Data Science">
            <List bulleted>
<List.Item><strong>Data Engineering:</strong> database design, signal filtering, normalization, NCDF4</List.Item>
<List.Item><strong>Statistics:</strong> non-linear regression, significance testing, bayesian inference criteria, principle component analysis</List.Item>
<List.Item><strong>Machine Learning:</strong> SciKit-Learn, Keras, Tensor Flow 2</List.Item>
            </List>
          </div>
          <div label="Electronics">
            <List bulleted>
<List.Item><strong>Circuit Design:</strong> analog circuits (ADCs and DACs), digital circuits, sensor, precision timing</List.Item>
<List.Item><strong>Printed Circuit Boards:</strong> PCB design, manufacturing, procurement, SMT assembly</List.Item>
<List.Item><strong>Board-to-Board Communication:</strong> I2C, SPI, UART, RS232, RS485</List.Item>
<List.Item><strong>Embedded Microcontrollers:</strong> Arduino, Teensy, PIC</List.Item>
<List.Item><strong>FPGAs:</strong> Altera, Xilinx</List.Item>
            </List>
          </div>
        </Tabs>
      </Container>


      <Container text style={{ marginTop: '2em' }}>
      <Header as='h1'>Occupations</Header>
        <div style={{height: '24em', overflow: 'auto', scroll: 'auto'}}>
          <Header as='h3'>Senior Software Engineer</Header>
          <i><span><Image src='/images/nasdaq-logo.png' floated='inline' size='mini' />NASDAQ OMX, Sydney Australia. 2022 - present</span></i>
          <List bulleted>
          <List.Item>Develop and deploy automation tools for Linux system administration</List.Item>
          <List.Item>Design scalable architectures for both local and cloud-based high-performance compute clusters</List.Item>
          </List>
          <Image src='/images/chrishall/CCH-UTS-Science_sm.jpg' floated='right' size='small' rounded />
          <Header as='h3'>Research Assocate/Associate Lecturer</Header>
          <i>University of Technology Sydney, Sydney Australia. 2018 - 2022</i>
          <List bulleted>
          <List.Item>Designed and built Industry-4.0 automation systems for use with microalgae, attracting more than $2 million AUD ($1.6 million USD) in research grant funding</List.Item>
          <List.Item>Invented novel IoT sensors for use in microalgal phenomics, producing 3 IP disclosures</List.Item>
          </List>
          <Header as='h3'>Co-Founder/Entrepreneur</Header>
          <Image src='/images/phenometrics-logo-2-200x67.png'  size='small' rounded />
          <i>Phenometrics Inc, Lansing Michigan. 2011 - Present</i>
          <List bulleted>
          <List.Item>Co-founded this comapny to build and sell my patented ePBRs, which I invented during my PhD</List.Item>
          <List.Item>Sales to customers in more than 7 countries across four continents: North America, Europe, Asia, and Australia.</List.Item>
          </List>
          <Header as='h3'>Research Assistant</Header>
          <i>Michigan State University: DOE Plant Research Laboratories, Lansing Michigan. 2011 - 2017</i>
          <List bulleted>
          <List.Item>Invented novel phenotyping methods and scientific instruments, co-authoring two patents 
            (<Link to='https://patents.google.com/patent/US9429521B2/en'>US9429521B2</Link>, <Link to='https://patents.google.com/patent/US9816065B2/en'>US9816065B2</Link>)</List.Item>
          <List.Item>Conducted ground-breaking research, co-authoring 3 peer-reviewed publications and created a new first-of-its-kind research facilty: 
            <Link to='https://prl.natsci.msu.edu/research-tech/center-for-advanced-algal-and-plant-phenotyping/why-caapp/'>The Center for Advanced Algal and Plant Phenotyping (CAAPP)</Link></List.Item>
          <List.Item>Published thesis book: <Link to='https://d.lib.msu.edu/etd/4679/datastream/OBJ/download/Photosynthesis_and_Hyperoxia.pdf'>Photosynthesis and Hyperoxia</Link></List.Item>
          </List>
          <Header as='h3'>Research Assistant</Header>
          <i>Washington State University, Pullman Washington. 2009 - 2010</i>
          <List bulleted>
          <List.Item>Used kinetic spectrophotometry to study the molecular mechanisms of photosynthesis, resulting in 
            a publication in the journal <Link to='https://www.pnas.org/content/108/50/20248'>Proceedings of the National Academy of Sciences of the United States of America</Link></List.Item>
          <List.Item>Developed FPGA-based precision time control devices for use in developing novel spectrophotometers</List.Item>
          </List>
          <Header as='h3'>Intern</Header>
          <i>R&D Systems, Minneapolis Minnesota. 2008</i>
          <List bulleted>
          <List.Item>Developed ELISA assays for blood proteins</List.Item>
          <List.Item>Used western blots to assess antibodies for suitability in ELISA assay development</List.Item>
          </List>
        </div>
      </Container>


      <Container text style={{ marginTop: '2em' }}>
      <Header as='h1'>Education</Header>
      <table border="0" style={{borderSpacing: '12px', verticalAlign: 'top'}}><tr>
        <td width="64" style={{textAlign: 'center'}}>
          <Image src='/images/MSU-Logo.png' circular />
        </td><td>
          <strong>PhD</strong><br/>
          <em>Plant Biology</em><br/>
          Michigan State University, Lansing Michigan. 2017
        </td>
      </tr><tr>
        <td width="64" style={{textAlign: 'center'}}>
          <Image src='/images/UMN-Logo.png' />
        </td><td>
          <strong>Bachelor of Science</strong><br/>
          <em>Biochemistry, Genetics</em><br/>
          University of Minnesota, Minneapolis/St. Paul Minnesota. 2009
        </td>
      </tr></table>
      </Container>


      <ContactInfo/>

    </Container>

    
    </main>
    <footer role="banner">
      <FooterBar />
    </footer>
    </div>
  )
}

export default AboutPage
