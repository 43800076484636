import * as React from "react"
import { Link } from "gatsby"


// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  Header,
} from 'semantic-ui-react'


// markup
const ContactInfo = () => {
  return (
<>
	<Header as='h1'>Contact Info</Header>
	<Container text>
		Dr. Christopher C. Hall<br/>
		<Link to="mailto:cchall@zoho.com">cchall@zoho.com</Link><br/>
		Rhodes, NSW 2138, Australia<br/>
	</Container>
</>
  )
}
export default ContactInfo;